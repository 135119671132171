import { CSSObject } from "@emotion/react";
import { Colors } from "../ui-library/design-tokens/colors";
import { Fonts } from "../ui-library/design-tokens/fonts";
import { grid } from "../ui-library/design-tokens/grid";

const mobileContainer: CSSObject = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  flexDirection: "column",
  backgroundColor: Colors.White,
  zIndex: 1000,
};

const header: CSSObject = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: grid(5),
  fontSize: 20,
  fontWeight: Fonts.Weight.Bold,
  color: Colors.White,
  backgroundColor: Colors.Black,
  paddingLeft: grid(2),
  paddingRight: grid(2),
};

const inputContainer: CSSObject = {
  backgroundColor: Colors.DarkGrey,
  padding: grid(2),
};

const suggestsContaineer: CSSObject = {
  flex: 1,
  overflowY: "auto",
};

const desktopContainer: CSSObject = {
  position: "relative",
};

const styles = {
  mobileContainer,
  header,
  inputContainer,
  suggestsContaineer,
  desktopContainer,
};

export default styles;
