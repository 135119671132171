import * as React from "react";
import CoinSuggests from "./coin-suggests";
import ExchangeSuggests from "./exchange-suggests";

interface SearchSuggestsProps {
  searchTerm: string;
  onItemClick: () => void;
}

// TODO: Trending first
const SearchSuggests: React.FC<SearchSuggestsProps> = ({
  searchTerm,
  onItemClick,
}) => {
  return (
    <div>
      <CoinSuggests searchTerm={searchTerm} onItemClick={onItemClick} />
      <ExchangeSuggests searchTerm={searchTerm} onItemClick={onItemClick} />
    </div>
  );
};

export default SearchSuggests;
