import { CSSObject } from "@emotion/react";
import { grid } from "../../ui-library/design-tokens/grid";

const dropdownContainer: CSSObject = {
  position: "absolute",
  top: grid(5.5),
  right: 0,
  width: "100%",
  zIndex: 10,
};

const styles = {
  dropdownContainer,
};

export default styles;
