import * as React from "react";
import { useClickOut } from "../../../hooks/use-click-out";
import Image from "../../ui-library/image/image";
import SearchIcon from "../assets/search-icon.svg";
import SearchFocusedIcon from "../assets/search-icon-grey.svg";
import ClearInputIcon from "../assets/clear-icon.svg";
import styles from "./search-input.styles";

interface SearchInputProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  isFocused: boolean;
  setFocused: (isFocused: boolean) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  searchTerm,
  setSearchTerm,
  isFocused,
  setFocused,
}) => {
  const inputRef = React.useRef<HTMLDivElement>(null);

  useClickOut({
    ref: inputRef,
    callback: () => setFocused(false),
  });

  const updateSearchTerm = (term: string) => {
    setSearchTerm(term);
  };

  const performSearch = () => {
    const topSuggest = document.querySelector(
      "[data-search-suggest]"
    ) as HTMLDivElement | null;
    if (topSuggest) {
      topSuggest.click();
    }
  };

  return (
    <form
      onSubmit={(e) => {
        performSearch();
        e.preventDefault();
      }}
      css={[styles.root, isFocused && styles.rootFocused]}
    >
      <Image
        src={isFocused ? SearchFocusedIcon : SearchIcon}
        alt="Search"
        turnOffLazyLoading={true}
      />

      <input
        type="search"
        css={styles.input}
        value={searchTerm}
        placeholder="Search"
        autoFocus={isFocused}
        onFocus={() => setFocused(true)}
        onChange={(e) => updateSearchTerm(e.target.value)}
      />

      {isFocused && searchTerm.length > 0 && (
        <div onClick={() => updateSearchTerm("")} css={styles.clearButton}>
          <Image src={ClearInputIcon} alt="Clear" turnOffLazyLoading={true} />
        </div>
      )}
    </form>
  );
};

export default SearchInput;
