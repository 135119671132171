import * as React from "react";
import AnimateHeight from "react-animate-height";
import { Card } from "../../ui-library/cards/card";
import SearchSuggests from "../search-suggests/search-suggests";
import styles from "./search-dropdown.styles";

interface SearchDropdownProps {
  searchTerm: string;
  isFocused: boolean;
  onItemClick: () => void;
}

const SearchDropdown: React.FC<SearchDropdownProps> = ({
  searchTerm,
  isFocused,
  onItemClick,
}) => {
  return (
    <div css={styles.dropdownContainer}>
      <AnimateHeight duration={200} height={isFocused ? "auto" : 0}>
        <Card pop={true}>
          <SearchSuggests searchTerm={searchTerm} onItemClick={onItemClick} />
        </Card>
      </AnimateHeight>
    </div>
  );
};

export default SearchDropdown;
