import * as React from "react";
import Fuse from "fuse.js";
import { useAllExchangesStatic } from "../../../hooks/static-query-hooks/use-all-exchanges-static";
import { EventCategory } from "../../../utils/analytics";
import { buildExchangeUrl } from "../../../utils/build-url";
import SearchResultList, { SuggestItem } from "./search-result-list";

const options = {
  isCaseSensitive: false,
  shouldSort: true,
  threshold: 0.9,
  keys: [
    {
      name: "name",
      weight: 1,
    },
  ],
};

interface ExchangeSuggestsProps {
  searchTerm: string;
  onItemClick: () => void;
}

const ExchangeSuggests: React.FC<ExchangeSuggestsProps> = ({
  searchTerm,
  onItemClick,
}) => {
  const exchanges = useAllExchangesStatic();

  const fuse = new Fuse(exchanges, options);
  const matchingExchanges = fuse
    .search(searchTerm)
    .map(({ item }) => ({
      id: item.id,
      name: item.name,
      image: item.image,
      url: item.slug ? buildExchangeUrl(item.slug) : undefined,
    }))
    .filter((item) => item.name && item.url)
    .slice(0, 4) as SuggestItem[];

  return (
    <div>
      <SearchResultList
        listName="Exchanges"
        items={matchingExchanges}
        onItemClick={onItemClick}
        hasSeparator={true}
        eventCategory={EventCategory.Site_Search}
      />
    </div>
  );
};

export default ExchangeSuggests;
