import * as React from "react";
import { EventCategory } from "../../../utils/analytics";
import Image from "../../ui-library/image/image";
import Link from "../../ui-library/link/link";
import Separator from "../../ui-library/separator/separator";
import styles from "./search-result-list.styles";

export interface SuggestItem {
  id: string;
  name: string;
  image?: string;
  info?: string;
  url: string;
}

interface SearchResultListProps {
  listName: string;
  listInfoName?: string;
  items: SuggestItem[];
  hasSeparator?: boolean;
  onItemClick: () => void;
  eventCategory: EventCategory;
}

const SearchResultList: React.FC<SearchResultListProps> = ({
  listName,
  listInfoName,
  items,
  hasSeparator,
  onItemClick,
  eventCategory,
}) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <div css={styles.root}>
      {hasSeparator && <Separator />}
      <div css={styles.header}>
        <span>{listName}</span>
        {listInfoName && <span>{listInfoName}</span>}
      </div>
      {items.map((item) => (
        <Link
          key={item.id}
          to={item.url}
          css={styles.item}
          data-search-suggest={item.id}
          onClick={onItemClick}
          analytics={{
            category: eventCategory,
            action: `Click - ${item.name}`,
            label: item.name,
          }}
        >
          <div css={styles.imageContainer}>
            <Image
              src={item.image}
              css={styles.image}
              alt={item.name}
              turnOffLazyLoading={true}
            />
          </div>
          <div css={styles.name}>{item.name}</div>
          {item.info && <div css={styles.info}>{item.info}</div>}
        </Link>
      ))}
    </div>
  );
};

export default SearchResultList;
