import { CSSObject } from "@emotion/react";
import { BoxShadow } from "../../ui-library/design-tokens/box-shadow";
import { Colors } from "../../ui-library/design-tokens/colors";
import { Fonts } from "../../ui-library/design-tokens/fonts";
import { grid } from "../../ui-library/design-tokens/grid";

const root: CSSObject = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: grid(4),
  fontSize: 14,
  fontWeight: Fonts.Weight.SemiBold,
  color: Colors.Black,
  backgroundColor: Colors.White,
  paddingLeft: grid(1.5),
  borderRadius: grid(4),
  boxShadow: BoxShadow.Default,
  transition: "all 0.2s ease",
  overflow: "hidden",
};

const rootFocused: CSSObject = {
  boxShadow: BoxShadow.Pop,
};

const input: CSSObject = {
  flex: 1,
  height: "100%",
  fontSize: 16,
  fontWeight: Fonts.Weight.SemiBold,
  color: Colors.Black,
  border: 0,
  outline: 0,
  backgroundColor: "transparent",
  WebkitAppearance: "none",
  marginLeft: grid(1),
  marginRight: grid(1),

  "&:focus": {
    outline: 0,
  },

  "::placeholder": {
    fontSize: 14,
    color: Colors.MidGrey,
  },
};

const clearButton: CSSObject = {
  width: grid(4),
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const styles = {
  root,
  rootFocused,
  input,
  clearButton,
};

export default styles;
