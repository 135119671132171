import { useStaticQuery, graphql } from "gatsby";
import { AllExchangesBasicQuery } from "../../../graphql-types";

export const useAllExchangesStatic = () => {
  const allExchanges = useStaticQuery<AllExchangesBasicQuery>(graphql`
    query AllExchangesBasic {
      allExchangeListItem(sort: { fields: [trust_score], order: [DESC] }) {
        nodes {
          id
          slug
          name
          image
        }
      }
    }
  `);

  return allExchanges.allExchangeListItem.nodes;
};
