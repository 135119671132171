import { useStaticQuery, graphql } from "gatsby";
import { AllCoinsBasicQuery } from "../../../graphql-types";

export const useAllCoinsStatic = () => {
  const allCoins = useStaticQuery<AllCoinsBasicQuery>(graphql`
    query AllCoinsBasic {
      allCoinListItem(sort: { fields: [market_cap_rank], order: [ASC] }) {
        nodes {
          id
          slug
          name
          symbol
          image
        }
      }
    }
  `);

  return allCoins.allCoinListItem.nodes;
};
