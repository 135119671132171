import { transparentize } from "polished";
import { CSSObject } from "@emotion/react";
import { Colors } from "../../ui-library/design-tokens/colors";
import { Fonts } from "../../ui-library/design-tokens/fonts";
import { grid } from "../../ui-library/design-tokens/grid";
import { Device } from "../../ui-library/design-tokens/media-queries";

const root: CSSObject = {
  paddingBottom: grid(1.5),
};

const header: CSSObject = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: grid(4),
  fontSize: 12,
  fontWeight: Fonts.Weight.SemiBold,
  color: Colors.MidGrey,
  paddingLeft: grid(2),
  paddingRight: grid(2),

  [Device.DesktopTablet]: {
    paddingLeft: grid(1.5),
    paddingRight: grid(1.5),
  },
};

const item: CSSObject = {
  display: "flex",
  alignItems: "center",
  height: grid(4),
  paddingLeft: grid(1.5),
  paddingRight: grid(1.5),

  ":hover": {
    backgroundColor: transparentize(0.4, Colors.LightestGrey),
  },
};

const imageContainer: CSSObject = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: grid(2),
  height: grid(2),
  marginRight: grid(1),
};

const image: CSSObject = {
  objectFit: "contain",
  maxWidth: "100%",
  maxHeight: "100%",
};

const name: CSSObject = {
  flex: 1,
  maxWidth: grid(17),
  fontSize: 14,
  fontWeight: Fonts.Weight.SemiBold,
  color: Colors.Black,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

const info: CSSObject = {
  maxWidth: grid(6),
  fontSize: 12,
  fontWeight: Fonts.Weight.SemiBold,
  color: Colors.MidGrey,
  marginLeft: "auto",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

const styles = {
  root,
  header,
  item,
  imageContainer,
  image,
  name,
  info,
};

export default styles;
