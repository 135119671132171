import * as React from "react";
import { SiteContext } from "../../site-context/site-context";
import { useClickOut } from "../../hooks/use-click-out";
import { EventCategory } from "../../utils/analytics";
import PillButton from "../ui-library/buttons/pill-button";
import Image from "../ui-library/image/image";
import SearchIcon from "./assets/search-icon.svg";
import SearchInput from "./search-input/search-input";
import styles from "./search.styles";
import SearchDropdown from "./search-dropdown/search-dropdown";
import SearchSuggests from "./search-suggests/search-suggests";

const Search: React.FC = () => {
  const { isMobile } = React.useContext(SiteContext);

  const containerRef = React.useRef<HTMLDivElement>(null);

  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [isFocused, setFocused] = React.useState<boolean>(false);

  useClickOut({
    ref: containerRef,
    callback: () => setFocused(false),
  });

  const searchInput = (
    <SearchInput
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      isFocused={isFocused}
      setFocused={setFocused}
    />
  );

  const handleClickItem = () => {
    setSearchTerm("");
    setFocused(false);
  };

  return isMobile ? (
    <>
      <PillButton
        name="Search"
        onClick={() => setFocused(true)}
        eventCategory={EventCategory.Site_Search}
      >
        <Image src={SearchIcon} alt="Search" turnOffLazyLoading={true} />
      </PillButton>
      {isFocused && (
        <div css={styles.mobileContainer}>
          <div css={styles.header}>
            Search
            <PillButton
              name="Close"
              size="small"
              color="blue"
              onClick={() => setFocused(false)}
              eventCategory={EventCategory.Site_Search}
            >
              Close
            </PillButton>
          </div>
          <div css={styles.inputContainer}>{searchInput}</div>
          <div css={styles.suggestsContaineer}>
            <SearchSuggests
              searchTerm={searchTerm}
              onItemClick={handleClickItem}
            />
          </div>
        </div>
      )}
    </>
  ) : (
    <div ref={containerRef} css={styles.desktopContainer}>
      {searchInput}
      <SearchDropdown
        searchTerm={searchTerm}
        isFocused={isFocused}
        onItemClick={handleClickItem}
      />
    </div>
  );
};

export default Search;
